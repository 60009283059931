import React, {useEffect, useRef, useState} from 'react';
import {Reservation, ReservationsProps} from "./Reservations";
import {
    Box,
    Button, Checkbox,
    createTheme, Dialog, DialogActions, DialogTitle,
    FormControl, FormControlLabel, IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider
} from "@mui/material";
import {format} from "date-fns";
import {Delete} from "@mui/icons-material";
import {collection, setDoc, doc, deleteDoc} from 'firebase/firestore';
import {db, Language} from "../App";
import {NavLink} from "react-router-dom";

interface Column {
    id: 'name' | 'phone' | 'adventureType' | 'startDate' | 'endDate' | 'numberOfAtvs';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number | Date | null) => string;
}

const columns: readonly Column[][] = [
    [],
    [
        {id: 'name', label: 'Nume',},
        {id: 'phone', label: 'Numar telefon',},
        {
            id: 'startDate',
            label: 'Data sosirii',
            format: (date) => format(date!, 'dd/MM/yyyy'),
        },
        {
            id: 'endDate',
            label: 'Data plecarii',
            format: (date) => !!date ? format(date, 'dd/MM/yyyy') : '',
        },
    ],
    [
        {id: 'name', label: 'Nume',},
        {id: 'phone', label: 'Numar telefon',},
        {
            id: 'startDate',
            label: 'Data sosirii',
            format: (date) => format(date!, 'dd/MM/yyyy'),
        },
        {
            id: 'numberOfAtvs',
            label: 'Nr. de ATV-uri',
        },
    ],
];

const Admin = ({reservations, language}: ReservationsProps) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    const [password, setPassword] = useState<string>('');
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [adventureType, setAdventureType] = useState<number>(1);

    const [selectedReservation, setSelectedReservation] = useState<Reservation | null>();
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [showPastReservations, setShowPastReservations] = useState<boolean>(false);

    const ADMIN_PASSWORD = 'admin2022';

    useEffect(() => {
        window.onresize = (e) => {
            setIsMobile(window.innerWidth <= 768);
        };
    }, [])

    const tryPassword = (e: any) => {
        e.preventDefault();
        if (password === ADMIN_PASSWORD) {
            setIsLoggedIn(true);
        }

        setPassword('');
    }

    const customTheme = createTheme({
        typography: {
            fontFamily: ['Jost', 'sans-serif'].join(',')
        },
        palette: {
            primary: {
                main: "#ff5300"
            }
        }
    });

    return (
        !isLoggedIn ? <form onSubmit={tryPassword}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100vw'} height={'100vh'}>
                    <Box width={!isMobile ? '50%' : '100%'} padding={'25px'} className={'row'}>
                        <Box display={'flex'} justifyContent={'center'} marginBottom={'25px'}>
                            <h2>Admin Login</h2>
                        </Box>
                        <TextField
                            autoFocus
                            className={'col-lg-10 col-sm-12'}
                            type={'password'}
                            label={'Parola'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <ThemeProvider theme={customTheme}>
                            <Button className={'col-lg-2 col-12'}
                                    variant={'contained'}
                                    style={{marginTop: isMobile ? 15 : 0}}
                                    type={'submit'}>
                                Conectare
                            </Button>
                        </ThemeProvider>
                    </Box>
                </Box>
            </form> :
            <>
                <Box padding={'25px'} height={'100vh'}>
                    <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>
                        {/*<img src={Logo} style={{width: isMobile ? '100vw' : '10vw', borderRadius: '20px'}}/>*/}
                        <NavLink to={'/'}>
                            Inapoi la website
                        </NavLink>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{
                                        color: "#ff5300",
                                    }}
                                    value={showPastReservations}
                                    onChange={(e) => setShowPastReservations(e.target.checked)}
                                />
                            }
                            label={'Rezervari trecute'}
                        />
                    </div>
                    <TextField
                        label={'Tipul aventurii'}
                        value={adventureType}
                        onChange={(v) => setAdventureType(parseInt(v.target.value))}
                        select
                        style={{paddingBottom: 15, minWidth: isMobile ? '100%' : '350px'}}
                    >
                        <MenuItem value={1}>Camping</MenuItem>
                        <MenuItem value={2}>Închiriere ATV/SSV</MenuItem>
                    </TextField>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns[adventureType].map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        style={{width: 60}}
                                    >
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reservations
                                    .filter((res) => res.adventureType === adventureType)
                                    .filter(res => {
                                        const currentDate = new Date();
                                        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

                                        if (showPastReservations) {
                                            const resStartDay = new Date(res.startDate.getFullYear(), res.startDate.getMonth(), res.startDate.getDate());
                                            const resEndDay = res.endDate ? new Date(res.endDate.getFullYear(), res.endDate.getMonth(), res.endDate.getDate()) : null;

                                            return resStartDay <= currentDay && (!resEndDay || resEndDay <= currentDay);
                                        } else {
                                            const resStartDay = new Date(res.startDate.getFullYear(), res.startDate.getMonth(), res.startDate.getDate());

                                            return resStartDay >= currentDay;
                                        }
                                    })
                                    .sort((r1, r2) => (showPastReservations ? (r2.startDate.getTime() - r1.startDate.getTime()) : (r1.startDate.getTime() - r2.startDate.getTime())))
                                    .map((res, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                {columns[adventureType].map((column) => {
                                                    const value = res[column.id];

                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {
                                                                column.format && (typeof value === 'number' || typeof value === 'object')
                                                                    ? (adventureType === 1 ? column.format(value) : format(value!, 'dd/MM/yyyy HH:mm'))
                                                                    : value?.toString()
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell
                                                    style={{width: 60}}
                                                >
                                                    <IconButton
                                                        onClick={() => {
                                                            setSelectedReservation(res);
                                                            setShowConfirmDialog(true);
                                                        }}
                                                    >
                                                        <Delete style={{color: 'red'}}/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Dialog
                    open={showConfirmDialog}
                    onClose={() => setShowConfirmDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Sunteti sigur ca doriti sa stergeti aceasta rezervare?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setShowConfirmDialog(false)}>Anuleaza</Button>
                        <Button onClick={async () => {
                            if (!selectedReservation)
                                return;

                            const reservationsCollection = collection(db, 'Reservations');
                            await deleteDoc(doc(reservationsCollection, selectedReservation!.id));
                            setShowConfirmDialog(false);
                        }} autoFocus>
                            Da
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
    );
}

export default Admin;