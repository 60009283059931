import React from 'react';

const EnglishTerms = () => {
    return (
        <>
            <h2 style={{textAlign: 'center'}}><b>TERMS AND CONDITIONS</b></h2>
            <div style={{padding: 48}}>
                <p>The website <a href="https://wildtrip.ro">https://wildtrip.ro</a> can be
                    used in accordance with the terms and conditions below.</p>
                <p><strong>Crăciunel Ciprian Iosif Întreprindere Individuala</strong> reserves the right to make changes to the content of
                    this site at any time, as well as to the
                    Terms and Conditions of use, without the need for prior notification to
                    Users/Clients.</p>
                <p><strong>Copyright</strong></p>
                <p>The content of the website www.wildtrip.ro consisting of images,
                    texts, graphic elements, videos, software - belongs entirely to the above-mentioned entities and is
                    protected in accordance with Law no. 8 / 14.03.1996 regarding copyright and related rights, as well
                    as Romanian and European laws regarding intellectual and industrial property.</p>
                <p>Usage of any of the elements listed above without the permission of <strong>Crăciunel Ciprian Iosif Întreprindere Individuala</strong>, except for
                    free images taken from the platforms mentioned above, is punishable according to applicable
                    legislation.</p>
                <p><strong>Crăciunel Ciprian Iosif Întreprindere Individuala</strong> may
                    offer the User / Client, through an agreement, the right to partially use the content of this
                    website, as described. This agreement applies strictly to the defined content, for a specified
                    period in the agreement, and only for the person/entity to whom the use of this content has been
                    granted, without being able to use other content of the wildtrip.ro website.</p>
                <p><strong>Collection and Processing of Personal Data</strong></p>
                <p>By "personal data" we mean any information about an identified natural person and, respectively, a
                    legal person: name, first name, email address, phone number, personal identification data, and
                    identification data of the natural or legal person (CNP/CUI/CIF), bank account data, general data
                    about the User's/Client's website and business.</p>
                <p>The methods of collecting personal data used by the website <a href="https://wildtrip.ro/">https://wildtrip.ro</a> are: through reservation forms.</p>
                <p>The purpose of collecting these personal data is to manage camping space reservations and ATV rentals.</p>
                <p><strong>Crăciunel Ciprian Iosif Întreprindere Individuala</strong> will not disclose, sell, or distribute databases
                    containing information about the personal data of its Users/Clients.</p>
                <p>In accordance with Law no. 677/2001 for the protection of individuals with regard to the
                    processing of personal data and the free movement of such data, as amended and supplemented, and Law
                    no. 506/2004 on the processing of personal data and the protection of private life in the field of
                    electronic communications, the company is obliged to administer, securely and only for the specified
                    purposes, the personal data provided by clients, a family member of theirs, or another person.</p>
                <p>The company guarantees its Clients the rights provided by Law no. 677/2001 regarding the protection of
                    individuals with regard to the processing of personal data and the free movement of such data,
                    namely the right to information, the right of access to data, the right of intervention, the right of
                    opposition, the right not to be subject to an individual decision, the right to address justice in
                    case of violation of their rights. Clients always have the right to request the complete or partial
                    deletion of personal data held by <strong>Crăciunel Ciprian Iosif Întreprindere Individuala</strong> and the
                    www.wildtrip.ro platform. Clients have the possibility to request any of the aforementioned rights
                    based on a written, signed, and dated request submitted to Business Generator SRL.</p>
                <p>The www.wildtrip.ro platform will not solicit, in any form, confidential information from its
                    Users, such as bank account information, personal passwords, etc. In case the User discloses such
                    information to third parties, they bear full responsibility for their actions. Therefore, in cases
                    of disclosing this information or other confidential data, the User cannot hold <strong>Crăciunel Ciprian Iosif Întreprindere Individuala</strong> liable for the damages caused.</p>
                <p>The website does not retain or store credit card information of customers in any form. This
                    information will only be accessed by the Transaction authorization institution or another entity
                    authorized to provide card identification data storage services, about which the Client/User will be
                    informed prior to entering the data.</p>
                <p><strong>Reservations</strong></p>
                <p>Reservations can be made through the website or through our agency. All reservations are subject to
                    availability and client selection and will be confirmed by our representatives over the phone.</p>
                <p><strong>ATV Usage</strong></p>
                <p>The ATV must be used in accordance with the instructions provided by our agency and with the
                    applicable law. The User will be responsible for any damages caused to the ATV.</p>
                <p>The geographical area where we propose to carry out recreational activities (ATV rides) is not in
                    protected natural areas and/or eco-friendly destinations.</p>
                <p><strong>Prices</strong></p>
                <p>The prices on the website are set in lei, include VAT, and are gross prices.</p>
                <p><strong>Recyclable Waste</strong></p>
                <p>The following categories of recyclable materials will be stored:</p>
                <ul>
                    <li>Paper, printed matter - will be stored packed and tied</li>
                    <li>Cardboard - cardboard boxes and cardboard packaging - are stored folded and tied</li>
                    <li>Metal - beverage cans, aluminum cans, aluminum caps - are stored crushed</li>
                    <li>Plastic material</li>
                    <ul>
                        <li>Foil - bags, sacks, etc.</li>
                        <li>Bottles: detergents, liquid soap, etc.</li>
                        <li>PET bottles: juice packaging, water, etc. - crushed and without caps</li>
                    </ul>
                </ul>
                <p>Depositing bags and packaging made of paper, cardboard, and polyethylene containing biodegradable organic materials (blood, meat, dairy, etc.) is prohibited.</p>
                <p><strong>Privacy Policy</strong></p>
                <p>For the use of the Wild Trip website, https://wildtrip.ro, it is necessary to read and accept these
                    rules in their entirety. Continuing to visit this site implies that you agree to these rules.</p>
                <p>Wild Trip may have information about you, information you provided through reservation. This
                    information includes your name and phone number.</p>
                <p><strong>How can Wild Trip use this personal information?</strong></p>
                <p>Information about you can be used by us only for purposes related to the commercialization of Wild
                    Trip services and products, such as:</p>
                <ol>
                    <li>Processing your orders or requests;</li>
                    <li>Providing products and/or services to you;</li>
                    <li>Billing;</li>
                    <li>Handling requests, questions, or complaints made by you;</li>
                    <li>Conducting market and product studies, as well as marketing Wild Trip products and services;</li>
                    <li>Recording information about you for the allocation or offering of rewards, discounts, and
                        similar programs.
                    </li>
                </ol>
            </div>
        </>
    )
}

export default EnglishTerms;
