import React, {ReactElement} from 'react';
import {Box} from "@mui/material";

type Props = {
    img: any;
    title: string;
    description?: ReactElement;
}

const ATVImageTitleDescriptionCard = (props: Props) => {
    return (
        <Box className="col-lg-4 col-sm-4" display={'flex'} justifyContent={'center'}>
            <div className="single-adventure">
                <img src={props.img} alt="adventure"/>
                <div className="adventure-content">
                    <a style={{textAlign: 'center'}} href="#reservations"><h5>{props.title}</h5></a>
                    {props.description}
                </div>
            </div>
        </Box>
    );
}

export default ATVImageTitleDescriptionCard;
