import React, {ReactElement} from 'react';
import {Box} from "@mui/material";

type Props = {
    img: any;
    title: string;
    description: ReactElement;
}

const ImageTitleDescriptionCard = (props: Props) => {
    return (
        <Box className="col-lg-6 col-sm-6" display={'flex'} justifyContent={'center'}>
            <div className="single-adventure">
                <img src={props.img} alt="adventure"/>
                <div className="adventure-content">
                    <a href="#reservations" style={{display: 'flex', justifyContent: 'center'}}><h4>{props.title}</h4></a>
                    {props.description}
                </div>
            </div>
        </Box>
    );
}

export default ImageTitleDescriptionCard;
